/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
.img-trabajos{
    max-width:95%;
    height:auto;
    margin-left:2.5%;
}
section {
  padding: 40px 0;
  overflow: hidden;
}

.section-bg {
  background-color: lighten($primary, 42);
}

.section-title {
    text-align: center;
    padding-bottom: 30px;

    h2 {
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 20px;
        padding-bottom: 0;
        font-family: $font-secondary;
        color: #ff5821;
    }

    p {
        margin-bottom: 0;
    }
}

.centrado {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.secundario {
    margin-top:20px;
    margin-bottom: 50px;
    text-align: center;

    h3 {
        color: #ff5821;
    }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: lighten($secondary, 70);
  margin-bottom: 40px;

  h2 {
    font-size: 28px;
    font-weight: 500;
  }

  ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;

    li + li {
      padding-left: 10px;
    }

    li + li::before {
      display: inline-block;
      padding-right: 10px;
      color: lighten($secondary, 10);
      content: "/";
    }
  }
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
  padding: 0 0 30px 0;
  position: relative;
  z-index: 3;

  .content {
    padding: 30px;
    background: $primary;
    border-radius: 4px;
    color: $white;

    h3 {
      font-weight: 700;
      font-size: 34px;
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 30px;
    }

    .more-btn {
      display: inline-block;
      background: rgba($white, .2);
      padding: 6px 30px 8px 30px;
      color: $white;
      border-radius: 50px;
      transition: all ease-in-out 0.4s;
      i {
        font-size: 14px;
      }

      &:hover {
        color: $primary;
        background: $white;
      }
    }

  
  }

  .icon-boxes {

    .icon-box {
      text-align: center;
      border-radius: 10px;
      background: $white;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
      padding: 40px 30px;
      width: 100%;

      i {
        font-size: 40px;
        color: $primary;
        margin-bottom: 30px;
      }

      h4 {
        font-size: 20px;
        font-weight: 700;
        margin: 0 0 30px 0;

      }
    
    
      p {
        font-size: 15px;
        color: lighten($default, 25);
      }
      
    }
  
  }

}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {

    .icon-boxes {

        h4 {
            font-size: 18px;
            color: lighten($secondary, 20);
            margin-bottom: 15px;
        }

        h3 {
            font-size: 28px;
            font-weight: 700;
            color: #ff5821;
            margin-bottom: 15px;
        }
    }


    .icon-box {
        margin-top: 40px;

        .icon {
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 64px;
            height: 64px;
            border: 2px solid lighten($primary, 30);
            border-radius: 50px;
            transition: 0.5s;
            background: $white;

            i {
                color: $primary;
                font-size: 32px;
            }
        }

        &:hover .icon {
            background: $primary;
            border-color: $primary;

            i {
                color: $white;
            }
        }

        .title {
            margin-left: 85px;
            font-weight: 700;
            margin-bottom: 10px;
            font-size: 18px;

            a {
                color: $black;
                transition: 0.3s;

                &:hover {
                    color: $primary;
                }
            }
        }

        .description {
            margin-left: 85px;
            line-height: 24px;
            font-size: 18px;
        }
    }

    .video-box {
        //background: url("../public/images/about.jpg") center center no-repeat;
        //background-size: cover;
        min-height: 500px;
    }

    .img-fluid2 {
        max-width: 100%;
        height: auto;
        object-fit: cover;
    }

    .play-btn {
        width: 94px;
        height: 94px;
        background: radial-gradient( $primary 50%, rgba($primary, 0.4) 52%);
        border-radius: 50%;
        display: block;
        position: absolute;
        left: calc(50% - 47px);
        top: calc(50% - 47px);
        overflow: hidden;
    }

    .play-btn::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-40%) translateY(-50%);
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid #fff;
        z-index: 100;
        transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    .play-btn::before {
        content: '';
        position: absolute;
        width: 120px;
        height: 120px;
        animation-delay: 0s;
        animation: pulsate-btn 2s;
        animation-direction: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: steps;
        opacity: 1;
        border-radius: 50%;
        border: 5px solid rgba($primary, 0.7);
        top: -15%;
        left: -15%;
        background: rgba(198, 16, 0, 0);
    }

    .play-btn:hover::after {
        border-left: 15px solid $primary;
        transform: scale(20);
    }

    .play-btn:hover::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-40%) translateY(-50%);
        width: 0;
        height: 0;
        border: none;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid #fff;
        z-index: 200;
        animation: none;
        border-radius: 0;
    }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1
  }
  100% {
    transform: scale(1, 1);
    opacity: 0
  }
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {

  .swiper-pagination {
    margin-top: 20px;
    position: relative;
  
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background-color: #fff;
      opacity: 1;
      border: 1px solid $primary;
    }
  
    .swiper-pagination-bullet-active {
      background-color: $primary;
    }
  }

  .swiper-slide {

    img {
      opacity: 0.5;
      filter: grayscale(100%);
      &:hover {
        filter: none;
        opacity: 1;
      }
    }
  }

}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services {

  .icon-box {
    padding: 50px 20px;
    margin-top: 35px;
    margin-bottom: 25px;
    text-align: center;
    height: 200px;
    position: relative;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
  }

  .icon {
    position: absolute;
    top: -36px;
    left: calc(50% - 36px);
    transition: 0.2s;
    border-radius: 50%;
    border: 6px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 72px;
    height: 72px;
    background: $primary;

    i {
      color: $white;
      font-size: 24px;
      line-height: 0;
    }

  }
  .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
    text-transform: uppercase;
    a {
      color: $black;
    }
  }

  .icon-box:hover  {
    .icon {
      background: $white;
  
      border: 2px solid $primary;
      i {
        color: $primary;
      }
    }

    .title a {
      color: $primary;
    }

  }

  .description {
    line-height: 24px;
    font-size: 14px;
  }
}
/*--------------------------------------------------------------
# inner-page
--------------------------------------------------------------*/
.inner-page {

    .card {
        border: 0;
        padding: 160px 20px 20px 20px;
        position: relative;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .img-fluid2 {
        max-width: 100%;
        height: auto;
        object-fit: cover;
    }

    .card-body {
        z-index: 10;
        background: rgba($white, .9);
        padding: 15px 30px;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
        transition: 0.3s;
        transition: ease-in-out 0.4s;
        border-radius: 5px;
    }

    .card-title {
        font-weight: 700;
        text-align: center;
        margin-bottom: 15px;

        a {
            color: $secondary;
        }
    }

    .card-text {
        color: lighten($default, 10);
    }

    .read-more {
        a {
            color: lighten($default, 20);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
            transition: 0.4s;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .card:hover {
        .card-body {
            background: $primary;
        }

        .read-more a, .card-title, .card-title a, .card-text {
            color: $white;
        }
    }
}

/*--------------------------------------------------------------
# Values
--------------------------------------------------------------*/
.values {

    .card {
        border: 0;
        padding: 160px 20px 20px 20px;
        position: relative;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .img-fluid2 {
        max-width: 100%;
        height: auto;
        object-fit: cover;
    }

    .card-body {
        z-index: 10;
        background: rgba($white, .9);
        padding: 15px 30px;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
        transition: 0.3s;
        transition: ease-in-out 0.4s;
        border-radius: 5px;
    }

    .card-title {
        font-weight: 700;
        text-align: center;
        margin-bottom: 15px;

        a {
            color: $secondary;
        }
    }

    .card-text {
        color: lighten($default, 10);
    }

    .read-more {
        a {
            color: lighten($default, 20);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
            transition: 0.4s;

            &:hover {
                text-decoration: underline;
                color:white;
            }
        }
    }

    .card:hover {
        .card-body {
            background: $primary;
        }

        .read-more a, .card-title, .card-title a, .card-text {
            color: $white;
        }
    }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  background: url("../public/images/about.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(darken($secondary, 30), 0.5);
  }

  .section-header {
    margin-bottom: 40px;
  }

  .testimonials-carousel, .testimonials-slider {
    overflow: hidden;
  }

  .testimonial-item {
    text-align: center;
    color: $white;

    .testimonial-img {
      width: 100px;
      border-radius: 50%;
      border: 6px solid rgba($white, .15);
      margin: 0 auto;
    }

    h3 {
      font-size: 20px;
      font-weight: bold;
      margin: 10px 0 5px 0;
      color: $white;
    }

    h4 {
      font-size: 14px;
      color: #ddd;
      margin: 0 0 15px 0;
    }

    .quote-icon-left, .quote-icon-right {
      color: rgba($white, .4);
      font-size: 26px;
    }

    .quote-icon-left {
      display: inline-block;
      left: -5px;
      position: relative;
    }

    .quote-icon-right {
      display: inline-block;
      right: -5px;
      position: relative;
      top:10px;
    }

    p {
      font-style: italic;
      margin: 0 auto 15px auto;
      color: #eee;
    }

  }

  .swiper-pagination {
    margin-top: 20px;
    position: relative;
  
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background-color: rgba(#fff, .5);
      opacity: 1;
    }
  
    .swiper-pagination-bullet-active {
      background-color: $primary;
    }
  }

  @media (min-width: 992px) {
    .testimonial-item p {
      width: 80%;
    } 
  }

}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio {

  #portfolio-flters {
    padding:0;
    margin:0 auto 35px auto;
    list-style:none;
    text-align:center;
    border-radius: 50px;
    padding: 2px 15px;

    li {
      cursor: pointer;
      display: inline-block;
      padding: 10px 20px 12px 20px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
      color: $default;
      margin-bottom: 5px;
      transition: all 0.3s ease-in-out;
      border-radius: 50px;

      &:hover, &.filter-active {
        color: $primary;
        background: lighten($primary, 40);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .portfolio-item {
    margin-bottom: 30px;

    .portfolio-info {
      opacity: 0;
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 0;
      z-index: 3;
      transition: all ease-in-out 0.3s;
      background: rgba($white, .9);
      padding: 15px;

      h4 {
        font-size: 18px;
        color: $white;
        font-weight: 600;
        color: $secondary;
      }

      p {
        color: lighten($secondary, 20);
        font-size: 14px;
        margin-bottom: 0;
      }

      .preview-link, .details-link {
        position: absolute;
        right: 40px;
        font-size: 24px;
        top: calc(50% - 18px);
        color: lighten($secondary, 10);
        &:hover {
          color: $primary;
        }
      }

      .details-link {
        right: 10px;
      }
      
    }

    .portfolio-links {
      opacity: 0;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 3;
      position: absolute;
      transition: all ease-in-out 0.3s;

      a {
        color: $white;
        margin: 0 2px;
        font-size: 28px;
        display: inline-block;
        transition: 0.3s;

        &:hover {
          color: lighten($primary, 20);
        }
      }

    }

    &:hover {
      .portfolio-info {
        opacity: 1;
        bottom: 20px;
      }
    }
  }
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {

  padding-top: 20px;

  .portfolio-details-slider {
    img {
      width: 100%;
    }
    .swiper-pagination {
      margin-top: 20px;
      position: relative;

      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        background-color: #fff;
        opacity: 1;
        border: 1px solid $primary;
      }

      .swiper-pagination-bullet-active {
        background-color: $primary;
      }
    }

  }

  .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba($secondary, 0.08);

    h3 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eee;
    }

    ul {
      list-style: none;
      padding: 0;
      font-size: 15px;

      li +li {
        margin-top: 10px;
      }
    }
    
  }

  .portfolio-description {
    padding-top: 30px;
    
    h2 {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 20px;
      text-align:center;
    }

    p {
      padding: 0;
    }
  }

}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {

  .member {
    margin-bottom: 20px;
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
    background: $white;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
    
    .member-img {
      position: relative;
      overflow: hidden;
    }

    .social {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 40px;
      opacity: 0;
      transition: ease-in-out 0.3s;
      background: rgba($white, 0.85);
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        transition: color 0.3s;
        color: $secondary;
        margin: 0 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:hover {
          color: $primary;
        }

      }

      i {
        font-size: 18px;
        line-height: 0;
      }
    }

    .member-info {
      padding: 25px 15px;
      h4 {
        font-weight: 700;
        margin-bottom: 5px;
        font-size: 18px;
        color: $secondary;
      }
  
      span {
        display: block;
        font-size: 13px;
        font-weight: 400;
        color: lighten($default, 40);
      }

      p {
        font-style: italic;
        font-size: 14px;
        line-height: 26px;
        color: lighten($default, 20);
      }

    }

    &:hover {

      .social {
        opacity: 1;
      }
    }

  }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing {

  .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }

  h3 {
    font-weight: 400;
    margin: -20px -20px 20px -20px;
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 600;
    color: lighten($default, 20);
    background: #f8f8f8;
  }

  h4 {
    font-size: 36px;
    color: $primary;
    font-weight: 600;
    font-family: $font-secondary;
    margin-bottom: 20px;

    sup {
      font-size: 20px;
      top: -15px;
      left: -3px;
    }

    span {
      color: #bababa;
      font-size: 16px;
      font-weight: 300;
    }
  }

  ul {
    padding: 0;
    list-style: none;
    color: $default;
    text-align: center;
    line-height: 20px;
    font-size: 14px;

    li {
      padding-bottom: 16px;
    }

    i {
      color: $primary;
      font-size: 18px;
      padding-right: 4px;
    }
    .na {
      color: #ccc;
      text-decoration: line-through;
    }
  }

  .btn-wrap {
    margin: 20px -20px -20px -20px;
    padding: 20px 15px;
    background: #f8f8f8;
    text-align: center;
  }

  .btn-buy {
    background: $primary;
    display: inline-block;
    padding: 6px 35px 8px 35px;
    border-radius: 4px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: $font-primary;
    font-weight: 600;
    box-shadow: 0 3px 7px rgba($primary, 0.4);
    transition: 0.3s;
    &:hover {
      background: lighten($primary, 10);
    }
  }

  .featured {
    h3 {
      color: $white;
      background: $primary;
      box-shadow: 0 3px 7px rgba($primary, 0.4)
    }
  }

  .advanced {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: $primary;
    color: $white;
  }

}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq  {

  .faq-list {
    padding: 0 100px;
    ul {
      padding: 0;
      list-style: none;
    }

    li + li {
      margin-top: 15px;
    }

    li {
      padding: 20px;
      background: #fff;
      border-radius: 4px;
      position: relative;
    }

    a {
      display: block;
      position: relative;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      padding: 0 30px;
      outline: none;
      cursor: pointer;
    }

    .icon-help {
      font-size: 24px;
      position: absolute;
      right: 0;
      left: 20px;
      color: lighten($primary, 25);
    }

    .icon-show, .icon-close {
      font-size: 24px;
      position: absolute;
      right: 0;
      top: 0;
    }

    p {
      margin-bottom: 0;
      padding: 10px 0 0 0;
    }

    .icon-show {
      display: none;
    }


    a.collapsed {
      color: $black;
      &:hover {
        color: $primary;
      }

      .icon-show {
        display: inline-block;
      }

      .icon-close {
        display: none;
      }
    }
  }

  @media (max-width: 1200px) {
    .faq-list {
      padding: 0;
    }
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {

    .info-box {
        color: $default;
        box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
        padding: 20px;
        height: 100%;

        i {
            font-size: 32px;
            color: $primary;
            border-radius: 50%;
            padding: 8px;
            border: 2px dotted lighten($primary, 35);
            float: left;
        }

        h3 {
            font-size: 20px;
            color: lighten($default, 20);
            font-weight: 700;
            margin: 10px 0 10px 68px;
        }

        p {
            padding: 0;
            line-height: 24px;
            font-size: 14px;
            margin: 0 0 0 68px;
        }
    }

    .btnenvio {
        background: #ff5821;
        border: 0;
        padding: 10px 24px;
        color: #fff;
        transition: 0.4s;

        &:hover {
            background: lighten($primary, 10%);
        }
    }


    .php-email-form {
        box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
        padding: 30px;

        .error-message {
            display: none;
            color: #fff;
            background: #ed3c0d;
            text-align: left;
            padding: 15px;
            font-weight: 600;

            & br + br {
                margin-top: 25px;
            }
        }



        .sent-message {
            display: none;
            color: #fff;
            background: #18d26e;
            text-align: center;
            padding: 15px;
            font-weight: 600;
        }

        .loading {
            display: none;
            background: #fff;
            text-align: center;
            padding: 15px;

            &:before {
                content: "";
                display: inline-block;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                margin: 0 10px -6px 0;
                border: 3px solid #18d26e;
                border-top-color: #eee;
                animation: animate-loading 1s linear infinite;
            }
        }

        input, textarea {
            border-radius: 0;
            box-shadow: none;
            font-size: 14px;

            &:focus {
                border-color: $primary;
            }
        }

        input {
            padding: 10px 15px;
        }

        textarea {
            padding: 12px 15px;
        }

        button[type="submit"] {
            background: $primary;
            border: 0;
            padding: 10px 24px;
            color: #fff;
            transition: 0.4s;

            &:hover {
                background: lighten($primary, 10%);
            }
        }
    }

    @keyframes animate-loading {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

