
#home {
    padding: 0;
    background-position: 100%;
    background-size: cover;
    height: calc(100vh - 110px);
    position: relative;
    width: 100%;

    img {
        object-fit: fill;
        width: 100%;
    }

    .swiper-slide img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .swiper-cube {
        overflow: visible;
        height: 0;
        height: 100%;
    }

    h1 {
        font-size: 80px;
        font-weight: bold;
        color: #ff5821;
        font-size: 25px;
        letter-spacing: 2px;
        padding-top: 10px;
        //text-shadow: 1px 1px 2px #000;
    }

    h2 {
        //text-shadow: 1px 1px 2px #000;
        color: #464646;
    }

    h3 {
        //text-shadow: 1px 1px 2px #000;
        color: #000;
        margin-top: 0.5em;
    }

    span {
        color: white;
        font-weight: bold;
    }

    .btn {
        background: rgba(0,0,0,0.6);
        border: none;

        &:hover {
            background: rgba(255,255,255,0.6);
        }
    }

    .slider-caption {
        position: relative;
        bottom: 30vh;
        background-color: rgba(255,255,255,0.8);
        color: white;
        padding: 4px;
        font-size: 17px;
        line-height: 18px;
    }

    @media (min-width: 1500px) {

        .slider-caption {
            bottom: 50vh;
        }
    }


    @media (max-width: 1024px), (max-widht: 1499px) {

        .slider-caption {
            bottom: 30vh;
        }
    }

    @media (max-width: 1024px), (max-widht: 768px) {


        .slider-caption {
            bottom: 20vh;
        }

        h1 {
            font-size: 24px;
        }

        h2 {
            font-size: 20px;
        }

        h3 {
            font-size: 20px;
        }
    }

    @media (max-width: 768px) {

        .slider-caption {
            bottom: 15vh;
        }

        h1 {
            font-size: 16px;
        }

        h2 {
            font-size: 14px;
        }

        h3 {
            font-size: 14px;
        }
    }

    @media (max-height: 640px) {

        .slider-caption {
            bottom: 15vh;
        }

        h1 {
            font-size: 16px;
        }

        h2 {
            font-size: 14px;
        }

        h3 {
            font-size: 14px;
        }
    }
}
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 60vh;
    background: url("../public/images/Portada.jpg") center center;
    background-size: cover;
    position: relative;
    z-index: 1;
    padding: 0;
    margin-bottom: -120px;

    &:before {
        content: "";
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
    }

    .container {
        z-index: 2;
    }


    h1 {
        margin: 0 0 10px 0;
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        color: #fff;
    }

    h2 {
        color: #eee;
        margin-bottom: 50px;
        font-size: 24px;
    }

    .get-started-icon {
        font-size: 24px;
        background: $primary;
        padding: 14px;
        color: $white;
        border-radius: 50px;
        position: relative;
        z-index: 5;
        box-shadow: 10px 2px 15px rgba(0, 0, 0, 0.10);
    }

    .btn-get-started {
        font-family: $font-primary;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 1px;
        display: inline-block;
        transition: 0.5s;
        margin-left: -10px;
        padding: 8px 26px 8px 26px;
        color: #fff;
        background: lighten($secondary, 6);
        border-radius: 0 50px 50px 0;
        position: relative;
        z-index: 4;

        &:hover {
            background: $primary;
        }
    }



    .swiperPrincipal {
        width: 100%;
        height: 100vh;
    }





    .templatemo_homewrapper {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (min-width: 1024px) {
        background-attachment: fixed;

        #home .slider-caption {
            bottom: 40vh;
        }
    }

    @media (max-width: 1024px), (max-height: 768px) {
        margin-bottom: -150px;
        height: 80vh;

        #home .slider-caption {
            bottom: 41vh;
        }
    }

    @media (max-width: 768px) {
        height: 80vh;

        h1 {
            font-size: 28px;
            line-height: 36px;
        }

        h2 {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 30px;
        }

        #home .slider-caption {
            bottom: 42vh;
        }
    }

    @media (max-height: 640px) {
        height: 120vh;

        #home .slider-caption {
            bottom: 44vh;
        }
    }
}
